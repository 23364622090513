<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      v-if="!loading"
      class="content-wrapper"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ user.user.name }} <small class="d-block">{{ user.convo.title }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <a
            class="btn btn-icon btn-secondary me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Back to Academy Awarded list"
            @click="loggedUser.roles.includes('super-admin') ? $router.push({name: 'admin.calls.academy_awarded.index'}) : $router.push({name: 'university.dashboard'})"
          ><i data-feather="chevron-left" /></a>
          <template
            v-if="loggedUser.roles.includes('super-admin') && user.icreaValidate"
          >
            <a
              class="btn btn-icon btn-primary me-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Validate icrea"
              @click="validateIcrea"
            >Validar premiat</a>
          </template>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template
              #button-content
            >
              <div class="btn-group dropup dropdown-icon-wrapper">
                <span
                  class="btn btn-icon btn-flat-secondary dropdown-toggle dropdown-toggle-split"
                >
                  <i
                    data-feather="more-vertical"
                  />
                </span>
              </div>
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <template v-if="user.allYears">
                <h6 class="dropdown-header">
                  Convocatòries
                </h6>
                <template v-for="year in user.allYears">
                  <a
                    :key="year.id"
                    class="dropdown-item"
                    @click="changeData(year.id)"
                  >{{ year.convo.year }}</a>
                </template>
              </template>
              <template v-if="user.canAskAnExtension">
                <div class="dropdown-divider" />
                <h6 class="dropdown-header">
                  Accions
                </h6>
                <b-dropdown-item
                  @click="openItemInfoSheet"
                >
                  <i data-feather="cast" />
                  Demanar una extensió
                </b-dropdown-item>
              </template>
            </div>
          </b-dropdown>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <!-- TEMPLATES ACADEMY CALL -->
        <template
          v-if="user.status.status === 8 && (user.convo.academy_scientific_memory || user.convo.academy_economy_memory)"
        >
          <h3>Memòria científica<span v-if="user.canViewEconomic || loggedUser.roles.includes('super-admin')"> & Memòria Econòmica</span></h3>
          <p class="mb-1">
            <a
              v-if="user.scientificMemory"
              :href="user.scientificMemory.url || ''"
              target="_blank"
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasGuidelineEvaluators"
              aria-controls="offcanvasGuidelineEvaluators"
            ><i data-feather="info" /> Descarregueu la plantilla de la memòria científica (.doc)</a>
            <a
              :href="`${url}/show-pdf/memoria_economica_fitxa_justificativa.xlsx`"
              target="_blank"
              class="btn btn-sm btn-flat-secondary"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasGuidelineEvaluators"
              aria-controls="offcanvasGuidelineEvaluators"
            ><i data-feather="info" /> Descarregueu la plantilla de la memòria econòmica, en el cas que justifiqueu l'últim any (.xlsx)</a>
          </p>
        </template>
        <!-- TABS -->
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            v-if="loggedUser.roles.includes('super-admin')"
            class="nav-item"
          > <!-- ACCEPTANCE DOC -->
            <a
              id="tab-acceptance"
              :class="['nav-link', { active: showTab === 'tab-acceptance' }]"
              data-bs-toggle="tab"
              aria-controls="tab-acceptance"
              role="tab"
              aria-selected="false"
              @click="showTab = 'tab-acceptance'"
            >Document d'acceptació <template v-if="user.acceptanceDocument.status == 2"><span

              class="badge bg-success ms-1"
            ><i data-feather="check" /></span>
            </template>
              <template v-else><span
                class="badge bg-danger ms-1"
              ><i data-feather="alert-triangle" /></span></template></a>
          </li>
          <template v-if="user.documents.length > 0">
            <template
              v-for="document in user.documents"
            >
              <li
                :id="'tab-justification-' + document.year"
                :key="document.id"
                class="nav-item"
              >
                <a
                  :class="['nav-link', { 'active': showTab === 'tab-justification-' + document.year }]"
                  data-bs-toggle="tab"
                  :aria-controls="'tab-justification-' + document.year"
                  role="tab"
                  aria-selected="false"
                  @click="showTab = 'tab-justification-' + document.year"
                >{{ document.year }}<span v-if="document.from_extension">(p)</span>
                  <template v-if="document.status == 2"><span
                    class="badge bg-success ms-1"
                  ><i data-feather="check" /></span>
                  </template>
                  <template v-else-if="document.status == 1"><span
                    class="badge bg-danger ms-1"
                  ><i data-feather="alert-triangle" /></span></template></a>
              </li>
            </template>
          </template>
        </ul>
        <div class="tab-content">
          <Document
            v-if="loggedUser.roles.includes('super-admin') && showTab === 'tab-acceptance'"
            tab-id="tab-acceptance"
            :title="'Convocatòria ICREA Acadèmia ' + user.convo.year"
            :sub-title="'Document d’Acceptació'"
            :user="user"
            :document="user.acceptanceDocument"
            :active="showTab === 'tab-acceptance'"
          />
          <template v-if="user.documents.length > 0">
            <template v-for="document in user.documents">
              <div
                v-if="showTab === 'tab-justification-' + document.year"
                :key="document.id"
              >
                <Document
                  v-if="document.status > 0"
                  :tab-id="'tab-justification-' + document.year"
                  :title="'Convocatòria ICREA Acadèmia ' + document.year"
                  sub-title="Document de Justificació Anual"
                  :user="user"
                  :document="document"
                  :active="showTab === 'tab-justification-' + document.year"
                />
                <div
                  v-else
                  :key="document.id"
                  class="alert alert-warning"
                >
                  <div class="alert-body">
                    <p>Aquest període no ha estat obert</p>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <div v-else>
            <div
              class="alert alert-warning"
            >
              <div class="alert-body">
                <p>No hi ha cap període obert</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ItemInfoSheet :table-id="filterInfoSheet">
        <template #info-sheet-title>
          Demana una extensió
        </template>
        <template
          #info-sheet
        >
          <div class="offcanvas-body">
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Mesos</label>
              <input
                v-model="extension.extension"
                type="number"
                class="form-control"
              >
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Descripció</label>
              <textarea
                id=""
                v-model="extension.reason"
                name=""
                cols=""
                rows="10"
                class="form-control"
              />
            </div>

          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="applyActions"
            >
              Enviar
            </button>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
    <div
      v-else
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import Document from './Components/Document.vue'
import EconomyMemory from './Components/EconomyMemory.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    Document,
    EconomyMemory,
    ItemInfoSheet,
  },
  data() {
    return {
      showTab: '',
      filterInfoSheet: 1,
      extension: {
        extension: null,
        reason: null,
      },
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'academyAwarded/item',
      loggedUser: 'auth/admin',
    }),
    url() {
      return Vue.prototype.$groupUrl
    },
  },
  async mounted() {
    await this.$store.dispatch('academyAwarded/fetchUser', this.$route.params.id)

    if (this.user.documents.length > 0) {
      this.showTab = `tab-justification-${this.user.documents[0].year}`
    } else if (this.user.canViewEconomic) {
      this.showTab = 'tab-economy'
    }

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
    this.loading = false
  },
  methods: {
    async changeData(id) {
      this.loading = true
      try {
        await this.$store.dispatch('academyAwarded/fetchUser', id)

        if (this.user.document) {
          this.showTab = 'tab-justification'
        } else if (this.user.canViewEconomic) {
          this.showTab = 'tab-economy'
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    openItemInfoSheet() {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
    },
    async applyActions() {
      try {
        if (!this.extension.extension || !this.extension.reason) {
          Vue.swal("Has d'introduir tots els camps!", '', 'error')
          return
        }

        this.loading = true
        await this.$store.dispatch('extensionForm/store', { ...this.extension, academy_awarded_id: this.user.id })
        Vue.swal('Has demanat una extensió correctament!', '', 'success')
        this.$store.dispatch('modals/toggleInfoItemSheet', false)
        await this.$store.dispatch('academyAwarded/fetchUser', this.user.id)
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    },
    async validateIcrea() {
      await this.$store.dispatch('academyAwarded/actions', { users: [{ id: this.user.id }], action: 8 })
      Vue.swal('Ha estat validat correctament!', '', 'success')
      await this.$store.dispatch('academyAwarded/fetchUser', this.user.id)
      setTimeout(() => {
      // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>

<style>

</style>
